/* eslint-disable jsx-a11y/anchor-is-valid */
import { Typography } from '@mui/material';
import Link from '@mui/material/Link';

function QuickLinks(props) {
  return (
    <div className="flex flex-col text-white space-y-2">
      <Typography className="!font-customBlack !font-900 !text-[16px] md:!text-[22px] !mb-10 md:!mb-20 lg:!mb-28">
        Quick Links
      </Typography>
      <Link
        href="/home"
        underline="hover"
        className="!text-white !font-400 !text-[12px] md:!text-[18px] !leading-[16px] md:!leading-[22px]"
      >
        Home
      </Link>
      <Link
        href="/ai-accounting"
        underline="hover"
        className="!text-white !font-400 !text-[12px] md:!text-[18px] !leading-[16px] md:!leading-[22px]"
      >
        Ai Accounting
      </Link>
      <Link
        href="/services"
        underline="hover"
        className="!text-white !font-400 !text-[12px] md:!text-[18px] !leading-[16px] md:!leading-[22px]"
      >
        Services
      </Link>
      <Link
        href="/team"
        underline="hover"
        className="!text-white !font-400 !text-[12px] md:!text-[18px] !leading-[16px] md:!leading-[22px]"
      >
        Team
      </Link>
      <Link
        href="/pricing"
        underline="hover"
        className="!text-white !font-400 !text-[12px] md:!text-[18px] !leading-[16px] md:!leading-[22px]"
      >
        Pricing
      </Link>
      <Link
        href="/clients"
        underline="hover"
        className="!text-white !font-400 !text-[12px] md:!text-[18px] !leading-[16px] md:!leading-[22px]"
      >
        Clients
      </Link>
      <Link
        href="/blog"
        underline="hover"
        className="!text-white !font-400 !text-[12px] md:!text-[18px] !leading-[16px] md:!leading-[22px]"
      >
        Blog
      </Link>
      <Link
        href="/contact-us"
        underline="hover"
        className="!text-white !font-400 !text-[12px] md:!text-[18px] !leading-[16px] md:!leading-[22px]"
      >
        Contact
      </Link>
      <Link
        href="/privacy-policy"
        underline="hover"
        className="!text-white !font-400 !text-[12px] md:!text-[18px] !leading-[16px] md:!leading-[22px]"
      >
        Privacy Policy
      </Link>
      <Link
        href="/terms-and-conditions"
        underline="hover"
        className="!text-white !font-400 !text-[12px] md:!text-[18px] !leading-[16px] md:!leading-[22px]"
      >
        Terms & Conditions
      </Link>
      <Link
        href="/terms-and-conditions#cancellation-policy"
        underline="hover"
        className="!text-white !font-400 !text-[12px] md:!text-[18px] !leading-[16px] md:!leading-[22px]"
      >
        Cancellation Policy
      </Link>
    </div>
  );
}

export default QuickLinks;
